<template lang="pug">
.wizard-step-form
  .t-h2-mobile.c-gray100.mt-6.mb-5 Информация о кредите
  validation-observer(ref="form")
    v-form
      v-row
        v-col
          .d-flex.align-center.mb-1
            .t-normal-bold.c-gray100.mr-1 Стоимость объекта
          .t-small.c-text--dimmed.mb-1 Текущая цена на торгах
          .text--price
            | {{ currentPriceFormatted }}
      v-row
        v-col
          .t-normal-bold.c-gray100 Первоначальный взнос
          .t-small.c-text--dimmed.mb-1 Рассчитывается автоматически
          .flex-1.text--price {{ computedInitialFee | currency }}

      v-row
        v-col
          .d-flex.align-center.mb-1
            .t-normal-bold.c-gray100 Сумма кредита
            ui-icon.c-gray60(
              name="alert"
              right
              v-tooltip.top="{ content: 'При изменении стоимости актива на торгах сумма кредита не меняется. Максимальная сумма кредита - 700 млн' }"
            )
          .t-small.c-text--dimmed.mb-1 Не более 70% от стоимости
          validation-provider(rules="required" v-slot="{ errors }" vid="initial_fee")
            ui-input-slider.text--price.mt-0(
              v-model="totalAmount"
              :max="totalAmountMax"
              :min="totalAmountMin"
            )
              .flex-1 {{ totalAmount | currency }}
            v-messages.mt-2.error--text(v-if="errors[0]" :value="errors")
      v-row
        v-col
          .t-normal-bold.c-gray100.mb-2 Срок кредита, мес
          .t-small.c-text--dimmed.mb-1 от 1 до 3 лет
          validation-provider(rules="required" v-slot="{ errors }" vid="term")
            ui-input-slider.text--price.mt-0(v-model="term" :max="maxTerm" :min="minTerm")
              .flex-1 {{ termFormatted }}
            v-messages.mt-2.error--text(v-if="errors[0]" :value="errors")
</template>

<script>
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import { UiBtn, UiIcon, UiInputSlider } from '@/uikit';
  import { pluralize } from '@vuejs-community/vue-filter-pluralize';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('Step3', 'green');

  extend('required', {
    ...required,
    message: 'Обязательно для заполнения',
  });

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      UiBtn,
      UiIcon,
      UiInputSlider,
    },

    inheritAttrs: false,
    props: {
      initData: { type: Object, default: () => {} },
      errors: { type: Object, default: () => {} },
    },

    data() {
      return {
        // редактируемое на форме
        term: undefined,
        initialFee: undefined,
        totalAmount: undefined,
        // прочее
        currentPrice: undefined,
        totalAmountMin: 1000000,
        totalAmountMax: undefined,
        currentPriceFormatted: undefined,
        minTerm: 12,
        maxTerm: 36,
      };
    },

    computed: {
      formData() {
        return {
          current_price: this.currentPrice,
          total_amount: this.totalAmount,
          initial_fee: this.computedInitialFee,
          term: this.term || 1,
        };
      },

      computedInitialFee() {
        return this.currentPrice - Number(this.totalAmount);
      },

      termFormatted() {
        let year = Math.floor(this.term / 12);
        let month = this.term % 12;
        month = month ? `${month} ${pluralize(month, 'ru', ['месяц', 'месяца', 'месяцев'])}` : '';
        year = `${year} ${pluralize(year, 'ru', ['год', 'года', 'годов'])}`;
        return `${year} ${month}`;
      },
    },

    watch: {
      errors: {
        handler(newValue) {
          if (this.$refs.form) {
            logger.log('333: проставляем ошибки', newValue);
            this.$refs.form.setErrors(newValue);
          }
        },
        immediate: true,
      },

      initData: {
        handler(newValue) {
          this.ingresData(newValue);
        },
        immediate: true,
      },

      formData: {
        handler(newValue) {
          this.$emit('update', newValue);
        },
        deep: true,
      },
    },

    methods: {
      ingresData(data) {
        this.term = data?.term || 1;
        this.initialFee = data?.initial_fee;
        this.currentPrice = data?.current_price;
        this.totalAmount = data?.total_amount;
        this.totalAmountMax = data?.total_amount;
        this.initialFeeFormatted = data?.initial_fee_formatted;
        this.currentPriceFormatted = data?.current_price_formatted;
      },

      async validate() {
        return await this.$refs.form.validate();
      },
    },
  };
</script>

<style lang="scss">
  .text--price {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
</style>
