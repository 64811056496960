<template lang="pug">
.wizard-step-form
  ui-alert(dense icon-only warning)
    .t-small Все поля в&nbsp;заявке являются обязательными для&nbsp;заполнения
  .t-h2-mobile.c-gray100.mt-6.mb-5 Сведения о заемщике

  validation-observer(ref="form")
    v-form
      h3.t-h4-mobile.c-gray100.mb-4 Компания
      v-row
        v-col
          v-autocomplete.input-rounded(
            v-model="autocompleteModel"
            :items="companies"
            item-text="name"
            placeholder="Поиск по ИНН и наименованию"
            attach
            clearable
            dense
            elevation="0"
            hide-details
            hide-no-data
            outlined
            return-object
            append-icon=""
            :filter="() => true"
            :menu-props="{ width: '100%' }"
            @input="onACinput"
            @update:search-input="onSearchInput"
            :loading="companiesLoading"
          )
            template(#prepend-inner)
              ui-icon.c-gray60(name="search")

            template(#item="{ parent, item }")
              v-list-item-content
                .v-list-item__title
                  span.mr-3(v-html="parent.genFilteredText(item.name)")
                  span.ml-.color-gray80(v-html="parent.genFilteredText(item.inn)")
                  // .category-select__item-subtitle {{ item.address }}
            template(#default="item")
              | {{ item.name }}

      v-row
        v-col
          .t-xsmall.c-text--dimmed.mb-1 ИНН
          validation-provider(rules="required|min:10" v-slot="{ errors }" vid="company_inn")
            v-text-field(
              v-model="form.companyInn"
              :placeholder="maska.company_inn.placeholder"
              autocomplete="off"
              hide-details="auto"
              key="company_inn"
              persistent-placeholder
              v-maska="maska.company_inn.maska"
              :error-messages="errors[0]"
            )
      v-row
        v-col
          .t-xsmall.c-text--dimmed.mb-1 Наименование компании / ИП
          validation-provider(rules="required" v-slot="{ errors }" vid="company_name")
            v-text-field(
              v-model="form.companyName"
              autocomplete="off"
              hide-details="auto"
              key="company_name"
              persistent-placeholder
              :error-messages="errors[0]"
            )
      v-row
        v-col
          .t-xsmall.c-text--dimmed.mb-1 Адрес регистрации
          validation-provider(rules="required" v-slot="{ errors }" vid="company_legal_address")
            v-text-field.pt-0.mt-0(
              v-model="form.companyLegalAddress"
              label=""
              autocomplete="off"
              hide-details="auto"
              key="company_legal_address"
              persistent-placeholder
              :error-messages="errors[0]"
            )

      h3.t-h4-mobile.c-gray100.mb-4.mt-16 Контакты
      v-row
        v-col
          .t-xsmall.c-text--dimmed.mb-1 ФИО контактного лица
          validation-provider(rules="required" v-slot="{ errors }" vid="first_contact_name")
            v-text-field.pt-0.mt-0(
              v-model="form.firstContactName"
              autocomplete="off"
              hide-details="auto"
              key="first_contact_name"
              persistent-placeholder
              :error-messages="errors[0]"
            )
      v-row
        v-col
          .t-xsmall.c-text--dimmed.mb-1 Мобильный телефон
          validation-provider(rules="required|phone" v-slot="{ errors }" vid="first_contact_phone")
            v-text-field.pt-0.mt-0(
              v-model="form.firstContactPhone"
              :placeholder="maska.phone.placeholder"
              autocomplete="off"
              hide-details="auto"
              key="first_contact_phone"
              persistent-placeholder
              v-maska="maska.phone.maska"
              :error-messages="errors[0]"
            )
      v-row
        v-col
          .t-xsmall.c-text--dimmed.mb-1 Электронная почта
          validation-provider(rules="required|email" v-slot="{ errors }" vid="first_contact_email")
            v-text-field.pt-0.mt-0(
              v-model="form.firstContactEmail"
              autocomplete="off"
              hide-details="auto"
              key="first_contact_email"
              persistent-placeholder
              :error-messages="errors[0]"
            )

      ui-btn.mt-16(
        v-if="!showSecondContact"
        large
        secondary
        width="100%"
        @click="showSecondContact = true"
      )
        ui-icon.mr-1(name="plus" size=16)
        | Добавить контактное лицо

      template(v-if="showSecondContact")
        v-row.mt-10
          v-col
            .t-xsmall.c-text--dimmed.mb-1 ФИО контактного лица
            validation-provider(rules="required" v-slot="{ errors }" vid="second_contact_name")
              v-text-field.pt-0.mt-0(
                v-model="form.secondContactName"
                autocomplete="off"
                hide-details="auto"
                key="second_contact_name"
                persistent-placeholder
                :error-messages="errors[0]"
              )
        v-row
          v-col
            .t-xsmall.c-text--dimmed.mb-1 Мобильный телефон
            validation-provider(
              rules="required|phone"
              v-slot="{ errors }"
              vid="second_contact_phone"
            )
              v-text-field.pt-0.mt-0(
                v-model="form.secondContactPhone"
                :placeholder="maska.phone.placeholder"
                autocomplete="off"
                hide-details="auto"
                key="second_contact_phone"
                persistent-placeholder
                v-maska="maska.phone.maska"
                :error-messages="errors[0]"
              )
        v-row
          v-col
            .t-xsmall.c-text--dimmed.mb-1 Электронная почта
            validation-provider(
              rules="required|email"
              v-slot="{ errors }"
              vid="second_contact_email"
            )
              v-text-field.pt-0.mt-0(
                v-model="form.secondContactEmail"
                autocomplete="off"
                hide-details="auto"
                key="second_contact_email"
                persistent-placeholder
                :error-messages="errors[0]"
              )
        ui-btn.mt-3(
          v-if="showSecondContact"
          large
          secondary
          width="100%"
          @click="clearSecondContact"
        )
          | Убрать второй контакт
</template>

<script>
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import { required, min, email } from 'vee-validate/dist/rules';
  import { UiBtn, UiIcon, UiAlert, UiDatePicker } from '@/uikit';
  import { maska } from 'maska';
  import Constants from '@/uikit/util/constants';

  import { SimpleApi as API } from '@/libs/api/lib/simpleApi';
  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('Step1Form', 'green');

  extend('required', { ...required, message: 'Обязательно для заполнения' });
  extend('min', { ...min, message: 'Минимальная длина: {length} символов' });
  extend('email', { ...email, message: 'Укажите корректный емейл' });
  extend('phone', {
    validate: (value) => value.match(Constants.PHONE_REGEX),
    message: 'Укажите корректный телефон',
  });

  export default {
    components: {
      UiBtn,
      UiIcon,
      UiAlert,
      UiDatePicker,
      ValidationObserver,
      ValidationProvider,
    },
    directives: { maska },
    props: {
      errors: { type: Object, default: () => {} },
      initData: { type: Object, default: () => {} },
    },

    data() {
      return {
        maska: Constants.fields,
        autocompleteModel: undefined,
        companies: [],
        companiesLoading: false,
        showSecondContact: false,
        selectedCompany: undefined,
        form: {
          companyInn: undefined,
          companyName: undefined,
          companyLegalAddress: undefined,
          firstContactName: undefined,
          firstContactPhone: undefined,
        },
      };
    },

    computed: {
      formData() {
        return {
          company_inn: this.form.companyInn,
          company_name: this.form.companyName,
          company_legal_address: this.form.companyLegalAddress,
          first_contact_name: this.form.firstContactName,
          first_contact_phone: this.form.firstContactPhone,
          first_contact_email: this.form.firstContactEmail,
          second_contact_name: this.form.secondContactName,
          second_contact_phone: this.form.secondContactPhone,
          second_contact_email: this.form.secondContactEmail,
        };
      },
    },

    watch: {
      errors: {
        handler(newValue) {
          this.$nextTick(() => {
            logger.log('111: проставляем ошибки', newValue);
            this.$refs?.form?.setErrors(newValue);
          });
        },
        immediate: true,
      },

      initData: {
        handler(newValue) {
          this.ingresData(newValue);
        },
        immediate: true,
      },

      formData: {
        handler(newValue) {
          this.$emit('update', newValue);
        },
        deep: true,
      },
    },

    mounted() {},

    methods: {
      ingresData(data) {
        this.form.companyInn = data?.company_inn;
        this.form.companyName = data?.company_name;
        this.form.companyLegalAddress = data?.company_legal_address;
        this.form.firstContactName = data?.first_contact_name;
        this.form.firstContactPhone = data?.first_contact_phone;
        this.form.firstContactEmail = data?.first_contact_email;
        this.form.secondContactName = data?.second_contact_name;
        this.form.secondContactPhone = data?.second_contact_phone;
        this.form.secondContactEmail = data?.second_contact_email;
      },

      async validate() {
        return await this.$refs.form.validate();
      },

      clearSecondContact() {
        this.showSecondContact = false;
        this.form.secondContactName = undefined;
        this.form.secondContactPhone = undefined;
        this.form.secondContactEmail = undefined;
      },

      onSearchInput(newVal) {
        if (!newVal || newVal === (this.autocompleteModel && this.autocompleteModel.name))
          return false; // не запрашиваем, если только что выбрали это значение
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.requestDadataCompany({
            query: newVal,
            type: 'legal',
          });
        }, 500); // пока продолжается ввод - не запрашиваем, 0,5с таймаут
      },

      async requestDadataCompany(payload) {
        try {
          if (!(payload && payload.query)) return false;
          this.companiesLoading = true;
          const response = await API.get('/api/v1/suggestions/companies.json', payload);
          this.companiesLoading = false;
          if (response.ok && Array.isArray(response.data)) {
            this.companies = response.data.map((el, i) => ({ ...el, id: i }));
          }
        } catch (error) {
          logger.error(error);
        }
      },

      onACinput(evt) {
        if (evt) {
          this.form.companyName = evt.name;
          this.form.companyInn = evt.inn;
          this.form.companyLegalAddress = evt.address;
        }
      },
    },
  };
</script>
