var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-step-form"},[_c('ui-alert',{attrs:{"dense":"","icon-only":"","warning":""}},[_c('div',{staticClass:"t-small"},[_vm._v("Все поля в заявке являются обязательными для заполнения")])]),_c('div',{staticClass:"t-h2-mobile c-gray100 mt-6 mb-5"},[_vm._v("Сведения о заемщике")]),_c('validation-observer',{ref:"form"},[_c('v-form',[_c('h3',{staticClass:"t-h4-mobile c-gray100 mb-4"},[_vm._v("Компания")]),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"input-rounded",attrs:{"items":_vm.companies,"item-text":"name","placeholder":"Поиск по ИНН и наименованию","attach":"","clearable":"","dense":"","elevation":"0","hide-details":"","hide-no-data":"","outlined":"","return-object":"","append-icon":"","filter":function () { return true; },"menu-props":{ width: '100%' },"loading":_vm.companiesLoading},on:{"input":_vm.onACinput,"update:search-input":_vm.onSearchInput},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('ui-icon',{staticClass:"c-gray60",attrs:{"name":"search"}})]},proxy:true},{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c('v-list-item-content',[_c('div',{staticClass:"v-list-item__title"},[_c('span',{staticClass:"mr-3",domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.name))}}),_c('span',{staticClass:"ml- color-gray80",domProps:{"innerHTML":_vm._s(parent.genFilteredText(item.inn))}})])])]}},{key:"default",fn:function(item){return [_vm._v(_vm._s(item.name))]}}]),model:{value:(_vm.autocompleteModel),callback:function ($$v) {_vm.autocompleteModel=$$v},expression:"autocompleteModel"}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("ИНН")]),_c('validation-provider',{attrs:{"rules":"required|min:10","vid":"company_inn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.maska.company_inn.maska),expression:"maska.company_inn.maska"}],key:"company_inn",attrs:{"placeholder":_vm.maska.company_inn.placeholder,"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.companyInn),callback:function ($$v) {_vm.$set(_vm.form, "companyInn", $$v)},expression:"form.companyInn"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Наименование компании / ИП")]),_c('validation-provider',{attrs:{"rules":"required","vid":"company_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"company_name",attrs:{"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Адрес регистрации")]),_c('validation-provider',{attrs:{"rules":"required","vid":"company_legal_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"company_legal_address",staticClass:"pt-0 mt-0",attrs:{"label":"","autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.companyLegalAddress),callback:function ($$v) {_vm.$set(_vm.form, "companyLegalAddress", $$v)},expression:"form.companyLegalAddress"}})]}}])})],1)],1),_c('h3',{staticClass:"t-h4-mobile c-gray100 mb-4 mt-16"},[_vm._v("Контакты")]),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("ФИО контактного лица")]),_c('validation-provider',{attrs:{"rules":"required","vid":"first_contact_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"first_contact_name",staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.firstContactName),callback:function ($$v) {_vm.$set(_vm.form, "firstContactName", $$v)},expression:"form.firstContactName"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Мобильный телефон")]),_c('validation-provider',{attrs:{"rules":"required|phone","vid":"first_contact_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.maska.phone.maska),expression:"maska.phone.maska"}],key:"first_contact_phone",staticClass:"pt-0 mt-0",attrs:{"placeholder":_vm.maska.phone.placeholder,"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.firstContactPhone),callback:function ($$v) {_vm.$set(_vm.form, "firstContactPhone", $$v)},expression:"form.firstContactPhone"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Электронная почта")]),_c('validation-provider',{attrs:{"rules":"required|email","vid":"first_contact_email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"first_contact_email",staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.firstContactEmail),callback:function ($$v) {_vm.$set(_vm.form, "firstContactEmail", $$v)},expression:"form.firstContactEmail"}})]}}])})],1)],1),(!_vm.showSecondContact)?_c('ui-btn',{staticClass:"mt-16",attrs:{"large":"","secondary":"","width":"100%"},on:{"click":function($event){_vm.showSecondContact = true}}},[_c('ui-icon',{staticClass:"mr-1",attrs:{"name":"plus","size":"16"}}),_vm._v("Добавить контактное лицо")],1):_vm._e(),(_vm.showSecondContact)?[_c('v-row',{staticClass:"mt-10"},[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("ФИО контактного лица")]),_c('validation-provider',{attrs:{"rules":"required","vid":"second_contact_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"second_contact_name",staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.secondContactName),callback:function ($$v) {_vm.$set(_vm.form, "secondContactName", $$v)},expression:"form.secondContactName"}})]}}],null,false,444942271)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Мобильный телефон")]),_c('validation-provider',{attrs:{"rules":"required|phone","vid":"second_contact_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.maska.phone.maska),expression:"maska.phone.maska"}],key:"second_contact_phone",staticClass:"pt-0 mt-0",attrs:{"placeholder":_vm.maska.phone.placeholder,"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.secondContactPhone),callback:function ($$v) {_vm.$set(_vm.form, "secondContactPhone", $$v)},expression:"form.secondContactPhone"}})]}}],null,false,4075987097)})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"t-xsmall c-text--dimmed mb-1"},[_vm._v("Электронная почта")]),_c('validation-provider',{attrs:{"rules":"required|email","vid":"second_contact_email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:"second_contact_email",staticClass:"pt-0 mt-0",attrs:{"autocomplete":"off","hide-details":"auto","persistent-placeholder":"","error-messages":errors[0]},model:{value:(_vm.form.secondContactEmail),callback:function ($$v) {_vm.$set(_vm.form, "secondContactEmail", $$v)},expression:"form.secondContactEmail"}})]}}],null,false,4035081055)})],1)],1),(_vm.showSecondContact)?_c('ui-btn',{staticClass:"mt-3",attrs:{"large":"","secondary":"","width":"100%"},on:{"click":_vm.clearSecondContact}},[_vm._v("Убрать второй контакт")]):_vm._e()]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }