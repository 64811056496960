<template lang="pug">
.wizard-step-form
  .t-h2-mobile.c-gray100.mt-6.mb-5 Информация об объекте
  .lot-info(style="position: relative")
    validation-observer(ref="form")
      v-form
        v-row(v-if="!currentLot")
          v-col(v-if="lots && lots.length")
            ui-alert.mb-3(success) На торгах продается {{ lots.length | lotsCount }}. Для подачи заявки выберите какой именно лот вы хотите купить.
            ui-btn(
              large
              key="btn-select-lot"
              secondary
              width="100%"
              @click="selectLot"
            )
              ui-icon(name="plus")
              | Выбрать лот

          v-col(v-if="lots && lots.length === 0")
            ui-alert(info) Поиск лотов в ЕФРСБ не дал результатов
            v-messages.error--text.mt-5(v-if="errorMsg('lot_id')" :value="[errorMsg('lot_id')]")

        v-row(v-if="currentLot")
          v-col.py-2
            .t-small.c-text--dimmed Номер лота
            .d-flex.align-center.justify-space-between.mt-05
              .t-normal
                | {{ currentLot.number || "Номер лота не указан" }}
              a.asset-trading__link.c-primary.d-flex.align-center(
                v-if="currentLot.url"
                :href="currentLot.url"
                target="_blank"
              )
                span.c-primary60 Посмотреть на ЕФРСБ
                ui-icon.c-gray70(name="open-window")

            .t-small.c-text--dimmed.mt-6 Электронная Торговая Площадка (ЭТП)
            .t-normal.mt-05
              | {{ currentLot.trader_name || "Торговая площадка не указана" }}

            template(v-if="currentLot.bankrupt_name")
              .t-small.c-text--dimmed.mt-6 Наименование должника
              .t-normal.mt-05
                | {{ currentLot.bankrupt_name }}

            .t-small.c-text--dimmed.mt-6 Текущая стадия торгов
            .t-normal.mt-05
              | {{ currentLot.status || "Стадия торгов не указана" }}

            template(v-if="currentLot.start_date && currentLot.end_date")
              .t-small.c-text--dimmed.mt-6 Период приема заявок
              .t-normal.mt-05
                | {{ new Date(currentLot.start_date) | dateFormat("DD.MM.YYYY") }} &mdash; {{ new Date(currentLot.end_date) | dateFormat("DD.MM.YYYY") }}

            template(v-if="currentLot.auction_date")
              .t-small.c-text--dimmed.mt-6 Начало торгов
              .t-normal.mt-05
                | {{ new Date(currentLot.auction_date) | dateFormat("DD.MM.YYYY") }}

            template(v-if="currentLot.description")
              .t-small.c-text--dimmed.mt-6 Описание лота
              ui-overflow-fader.mt-05(btn-text="Показать больше" max-height=72)
                .t-normal
                  | {{ currentLot.description }}

            ui-btn.mt-8(
              v-if="lots.length > 1"
              key="btn-select-annother-lot"
              third
              @click="selectLot"
            )
              ui-icon(name="renew" left)
              | Выбрать другой лот

        v-row(v-if="currentLot")
          v-col.py-2
            .lot-info(v-if="asset")
              .lot-info__asset(style="width: 320px")
                asset-card.outlined(:asset="asset")

        validation-provider(
          mode="passive"
          rules="required_lot"
          v-slot="{ errors }"
          vid="lot_id"
        )
          input(v-model="currentLotId" type="hidden")
          template
            ui-alert.mt-5(v-if="errors[0]" error) {{ errors[0] }}

  lot-selection-dialog(ref="lotSelectionDialog")
</template>

<script>
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import { UiBtn, UiIcon, UiAlert, UiOverflowFader } from '@/uikit';
  import AssetCard from '@/components/asset/AssetCard.vue';
  import LotSelectionDialog from '@/components/LotSelectionDialog.vue';
  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('Step2', 'green');

  extend('required_lot', {
    ...required,
    message: 'Необходимо указать лот',
  });

  export default {
    components: {
      UiBtn,
      UiIcon,
      UiAlert,
      UiOverflowFader,
      LotSelectionDialog,
      AssetCard,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      initData: { type: Object, default: () => {} },
      errors: { type: Object, default: () => {} },
    },

    data() {
      return {
        lotId: undefined,
        lotAuctionId: undefined,
        lotNumber: undefined,
        lotStatus: undefined,
        lotTraderName: undefined,
        lotDescription: undefined,
        lotUrl: undefined,
        lotStartDate: undefined,
        lotEndDate: undefined,
        lotAuctionDate: undefined,
        lotStartPrice: undefined,
        lotBankruptName: undefined,
        // прочее
        lots: [],
        currentLotId: undefined,
        asset: undefined,
      };
    },

    computed: {
      formData() {
        return {
          lot_id: this.lotId,
          lot_auction_id: this.lotAuctionId,
          lot_number: this.lotNumber,
          lot_status: this.lotStatus,
          lot_trader_name: this.lotTraderName,
          lot_description: this.lotDescription,
          lot_url: this.lotUrl,
          lot_start_date: this.lotStartDate,
          lot_end_date: this.lotEndDate,
          lot_auction_date: this.lotAuctionDate,
          lot_start_price: this.lotStartPrice,
          lot_bankrupt_name: this.lotBankruptName,
        };
      },

      currentLot() {
        if (!this.currentLotId || !this.lots || !this.lots.length) {
          return undefined;
        }
        const lot = this.lots.find((lot) => lot.lot_id == this.currentLotId);
        return lot;
      },
    },

    watch: {
      errors: {
        handler(newValue) {
          if (this.$refs.form) {
            this.$refs.form.setErrors(newValue);
          }
        },
        immediate: true,
      },

      initData: {
        handler(newValue) {
          this.ingresData(newValue);
        },
        immediate: true,
      },
    },

    methods: {
      ingresData(data) {
        this.asset = data?.asset;
        this.lots = data?.lots;

        // если только один лот доступен — сразу его выбираем
        const singleLot = this.lots && this.lots.length == 1 ? this.lots[0] : undefined;
        if (singleLot !== undefined) {
          this.currentLotId = singleLot.lot_id;
          this.setApplicationLot(singleLot);
        }
      },

      async validate() {
        return await this.$refs.form.validate();
      },

      async resetValidation() {
        return await this.$refs.form.reset();
      },

      setApplicationLot(lot) {
        this.lotId = lot.lot_id;
        this.lotAuctionId = lot.auction_id;
        this.lotNumber = lot.number;
        this.lotStatus = lot.status;
        this.lotTraderName = lot.trader_name;
        this.lotDescription = lot.description;
        this.lotUrl = lot.url;
        this.lotStartDate = lot.start_date;
        this.lotEndDate = lot.end_date;
        this.lotAuctionDate = lot.auction_date;
        this.lotStartPrice = lot.start_price;
        this.lotBankruptName = lot.bankrupt_name;
        this.$emit('update', this.formData);
      },

      async selectLot() {
        try {
          const lot = await this.$refs.lotSelectionDialog.open(this.lots, this.currentLotId);
          this.currentLotId = lot.lot_id;
          this.setApplicationLot(lot);
        } catch (error) {
          logger.error(error);
        }
      },
    },
  };
</script>
