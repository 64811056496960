/* Dependencies ============================================================= */
import { createLogger } from '@/uikit/util/logger';
const logger = createLogger('CreditApplicationModule', 'green');

/* State ==================================================================== */
const state = () => ({
  initialized: false,
  asset_id: undefined,
  // шаг 1
  company_inn: undefined,
  company_name: undefined,
  company_legal_address: undefined,
  first_contact_name: undefined,
  first_contact_phone: undefined,
  first_contact_email: undefined,
  second_contact_name: undefined,
  second_contact_phone: undefined,
  second_contact_email: undefined,
  // шаг 2
  lots: [],
  lot_auction_date: undefined,
  lot_auction_id: undefined,
  lot_bankrupt_name: undefined,
  lot_description: undefined,
  lot_end_date: undefined,
  lot_id: undefined,
  lot_number: undefined,
  lot_start_date: undefined,
  lot_start_price: undefined,
  lot_status: undefined,
  lot_trader_name: undefined,
  lot_url: undefined,
  asset: {},
  // шаг 3
  initial_fee: undefined,
  initial_fee_formatted: undefined,
  current_price: undefined,
  current_price_formatted: undefined,
  term: undefined,
  total_amount: undefined,
  total_amount_formatted: undefined,
  total_amount_max: undefined,

  // ошибки при сохранении
  errors: {},
});

/* Getters ================================================================== */
const getters = {
  errors: (state) => {
    return state.errors;
  },

  lots: (state) => {
    return state.lots;
  },

  application: (state) => {
    return {
      asset_id: state.asset_id,
      company_inn: state.company_inn,
      company_legal_address: state.company_legal_address,
      company_name: state.company_name,
      current_price: state.current_price,
      current_price_formatted: state.current_price_formatted,
      first_contact_email: state.first_contact_email,
      first_contact_name: state.first_contact_name,
      first_contact_phone: state.first_contact_phone,
      second_contact_email: state.second_contact_email,
      second_contact_name: state.second_contact_name,
      second_contact_phone: state.second_contact_phone,
      initial_fee: state.initial_fee,
      initial_fee_formatted: state.initial_fee_formatted,
      lot_auction_date: state.lot_auction_date,
      lot_auction_id: state.lot_auction_id,
      lot_bankrupt_name: state.lot_bankrupt_name,
      lot_description: state.lot_description,
      lot_end_date: state.lot_end_date,
      lot_id: state.lot_id,
      lot_number: state.lot_number,
      lot_start_date: state.lot_start_date,
      lot_start_price: state.lot_start_price,
      lot_status: state.lot_status,
      lot_trader_name: state.lot_trader_name,
      lot_url: state.lot_url,
      term: state.term,
      total_amount: state.total_amount,
      total_amount_formatted: state.total_amount_formatted,
      total_amount_max: state.total_amount_max,
      asset: state.asset,
    };
  },

  applicationInitialized: (state) => {
    logger.log('getter: applicationInitialized', state.initialized);
    return state.initialized;
  },
};

/* Mutations ================================================================ */

const mutations = {
  SET_INITIALIZED(state, flag) {
    state.initialized = flag;
  },

  SET_ERRORS(state, errors) {
    state.errors = errors;
  },

  SET_APPLICATION(state, application) {
    state.asset_id = application.asset_id;
    state.company_inn = application.company_inn;
    state.company_legal_address = application.company_legal_address;
    state.company_name = application.company_name;
    state.current_price = application.current_price;
    state.current_price_formatted = application.current_price_formatted;
    state.first_contact_email = application.first_contact_email;
    state.first_contact_name = application.first_contact_name;
    state.first_contact_phone = application.first_contact_phone;
    state.initial_fee = application.initial_fee;
    state.initial_fee_formatted = application.initial_fee_formatted;
    state.lot_auction_date = application.lot_auction_date;
    state.lot_auction_id = application.lot_auction_id;
    state.lot_bankrupt_name = application.lot_bankrupt_name;
    state.lot_description = application.lot_description;
    state.lot_end_date = application.lot_end_date;
    state.lot_id = application.lot_id;
    state.lot_number = application.lot_number;
    state.lot_start_date = application.lot_start_date;
    state.lot_start_price = application.lot_start_price;
    state.lot_status = application.lot_status;
    state.lot_trader_name = application.lot_trader_name;
    state.lot_url = application.lot_url;
    state.second_contact_email = application.second_contact_email;
    state.second_contact_name = application.second_contact_name;
    state.second_contact_phone = application.second_contact_phone;
    state.term = application.term;
    state.total_amount = application.total_amount;
    state.total_amount_formatted = application.total_amount_formatted;
    state.total_amount_max = application.total_amount_max;
    state.asset = application.asset;
  },

  SET_LOTS(state, lots) {
    state.lots = lots;
  },
};

/* Actions ================================================================== */

const actions = {
  initApplication({ commit }, application) {
    logger.log('Проставляем начальные данные заявки', application);
    commit('SET_APPLICATION', application);
    commit('SET_INITIALIZED', true);
  },

  setLots({ commit }, lots) {
    logger.log('Проставляем данные лотов', lots);
    commit('SET_LOTS', lots);
  },

  setErrors({ commit }, errors) {
    logger.log('Проставляем ошибки сохранения', errors);
    const err = {};
    Object.keys(errors).forEach((fieldName) => {
      err[fieldName] = [errors[fieldName]];
    });
    logger.log('Собранные ошибки!', err);
    commit('SET_ERRORS', err);
  },

  updateApplication({ commit, getters }, newData) {
    logger.log('Обновляем данные заявки', newData);
    const application = Object.assign({}, getters.application, newData);
    commit('SET_APPLICATION', application);
  },
};

/* Exports ================================================================== */
export default {
  state,
  getters,
  actions,
  mutations,
};
