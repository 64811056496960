<template lang="pug">
v-dialog(v-model="isActive" content-class="lots-selection-dialog" fullscreen)
  base-dialog-card(
    confirm-text="Выбрать"
    title="Выбрать лот на торгах"
    @click:cancel="cancel"
    @click:close="cancel"
    @click:confirm="confirm"
  )
    v-item-group(v-model="lotIndex" mandatory)
      v-item(v-for="(lot, index) in lots" :key="'lot' + index" v-slot="{ active, toggle }")
        .asset-trading__lot.card-sheet.outlined.pt-6.pb-10.mb-3.ui-clickable(@click="toggle")
          .asset-trading__summary.mb-4
            .asset-trading__title.ui-clickable.mb-2
              h5(:class="{ 'c-primary': false }") Лот №{{ lot.number }}
              .t-middle.c-text {{ lot.status }}
            .asset-trading__price
              .t-small.c-text.mb-1 Начальная цена
              .asset-trading__price--title.text--normal.font-weight-bold.c-text {{ lot.start_price_formatted }}
            a.asset-trading__link(v-if="lot.url" :href="lot.url" target="_blank")
              span Посмотреть на ЕФРСБ
              ui-icon.c-gray70(name="open-window")
          .asset-trading__details
            .asset-trading__details--item
              .t-small.c-text.mb-1 ЭТП
              .t-middle {{ lot.trader_name }}
            .asset-trading__details--item(v-if="lot.bankrupt_name")
              .t-small.c-text.mb-1 Наименование должника
              .t-middle {{ lot.bankrupt_name }}
            .asset-trading__details--item
              .t-small.c-text.mb-1 Статус торгов
              .t-middle {{ lot.status }}
            .asset-trading__details--item
              .t-small.c-text.mb-1 Период приема заявок на участие в торгах
              .t-middle
                template(v-if="lot.start_date && lot.end_date")
                  | {{ new Date(lot.start_date) | dateFormat("DD.MM.YYYY") }} — {{ new Date(lot.end_date) | dateFormat("DD.MM.YYYY") }}
                template(v-else)
                  | Нет данных
            .asset-trading__details--item
              .t-small.c-text.mb-1 Дата торгов
              .t-middle
                template(v-if="lot.auction_date")
                  | {{ new Date(lot.auction_date) | dateFormat("DD.MM.YYYY") }}
                template(v-else)
                  | Нет данных
</template>

<script>
  import BaseDialogCard from '@/components/BaseDialogCard.vue';
  import { UiIcon } from '@/uikit';

  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('LotSelectionDialog', 'orange');

  export default {
    name: 'lot-selection-dialog',
    components: {
      UiIcon,
      BaseDialogCard,
    },

    data() {
      return {
        isActive: false,
        resolve: null,
        reject: null,
        options: {},
        lots: [],
        lotIndex: 0,
        lotId: undefined,
        loading: false,
      };
    },

    methods: {
      setLotIndex(lotId) {
        if (!lotId) {
          this.lotIndex = 0;
          this.lotId = this.lots[0].lot_id;
          return;
        }
        this.lotIndex = this.lots.findIndex((lot) => {
          lot.lot_id == lotId;
        });
        this.lotId = lotId;
      },

      open(lots, lotId, options) {
        options = options || {};
        this.options = { ...this.options, ...options };
        this.lots = lots;
        this.setLotIndex(lotId);
        this.isActive = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },

      confirm() {
        const lot = this.lots[this.lotIndex];

        this.lotId = lot.lot_id;
        this.resolve(lot);
        this.isActive = false;
      },

      cancel() {
        this.resolve(undefined);
        this.isActive = false;
      },
    },
  };
</script>

<style lang="scss">
  .lots-selection-dialog {
    .asset-trading__lot {
      box-shadow: shadow('small');
    }
    .asset-trading__details--item {
      margin-bottom: 8px;
    }

    .asset-trading__lot.v-item--active {
      box-shadow: shadow('small'), shadow-border(all, 2px, c('primary60'));
    }

    .asset-trading__title {
      min-width: 300px;
    }
    .asset-trading__price {
      padding: 0 0;
      min-width: 160px;
    }
    .asset-trading__link {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
</style>
